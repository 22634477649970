import { SEARCH_BOX_WIDGET_CONFIG } from '@config/widgets/searchBox/searchBoxWidgetConfig';
import { logError } from '@domains/shared/helpers/logger';
import type { SegmentType } from '@type/search/segmentTypes';
import type { URLSegment } from '@type/search/urlSegments';

const { possibleSegments } = SEARCH_BOX_WIDGET_CONFIG.urlSegments;

export const getSegmentByValue = (
    value: string,
    segmentType: SegmentType,
    shouldLogError = true,
): URLSegment | null => {
    // some segments are supported only by particular sites
    if (!possibleSegments[segmentType]) {
        return null;
    }

    // Find the one with `value`
    const segment = possibleSegments[segmentType].find((element: URLSegment) => element.value === value);

    // Validate segment value
    if (!segment) {
        // There are cases when no estate is provided, hence it will throw error.
        // Those cases should be handled elsewhere.

        if (shouldLogError) {
            logError(`Incorrect label in URL segment: [${segmentType}]: ${value}`);
        }

        return null;
    }

    return segment;
};
