export const SORTING_BY = {
    none: 'NONE',
    default: 'DEFAULT',
    latest: 'LATEST',
    price: 'PRICE',
    area: 'AREA',
    bestMatch: 'BEST_MATCH',
} as const;

export type SortingBy = ObjectValues<typeof SORTING_BY>;

export const SORTING_BY_VALUES: SortingBy[] = Object.values(SORTING_BY);

export const STANDARD_SORTING_VALUES: SortingBy[] = ['DEFAULT', 'LATEST', 'PRICE', 'AREA'];

/** Includes option for an experiment with best matched results */
export const EXPERIMENTAL_SORTING_VALUES: SortingBy[] = ['DEFAULT', 'BEST_MATCH', 'LATEST', 'PRICE', 'AREA'];
