export interface DropdownOption<Value extends string = string> {
    value: Value;
    label: string;
}

export interface ExplicitDropdownOption<T> {
    label: string;
    value: T;
}

export const checkIsOption = <TValue = string>(data: unknown): data is ExplicitDropdownOption<TValue> => {
    return (
        !!data &&
        Object.prototype.hasOwnProperty.call(data, 'value') &&
        Object.prototype.hasOwnProperty.call(data, 'label')
    );
};
