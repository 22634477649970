import { ROUTE_SEGMENT } from '@config/routes';
import { SEARCH_CONFIG } from '@config/search/searchConfig';
import { SEARCH_BOX_WIDGET_CONFIG } from '@config/widgets/searchBox/searchBoxWidgetConfig';
import { URL_LOCATION_FILLER } from '@domains/shared/consts/urlLocationFiller';
import { checkIsArrayValue } from '@domains/shared/helpers/checkIsArrayValue';
import { createLocationsArrayString } from '@domains/shared/helpers/createLocationsArrayString';
import { createSearchParams } from '@domains/shared/helpers/createSearchParams';
import type { Locale } from '@lib/i18n/types/locale';
import type { ExplicitDropdownOption } from '@type/search/dropdownOption';
import type { Estate } from '@type/search/filters/estate';
import { ESTATE } from '@type/search/filters/estate';
import type { Market } from '@type/search/filters/market';
import { MARKET_VARIANTS } from '@type/search/filters/market';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import { TRANSACTION } from '@type/search/filters/transaction';
import type { UseTypes } from '@type/search/filters/useTypes';
import type { TreeNode } from '@type/search/location/pickerTreeNode';
import type { SearchPageVariant } from '@type/search/pageVariant';
import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';
import type { Location, SearchingFilters } from '@type/search/searchingFilters';
import { SEGMENT_TYPE } from '@type/search/segmentTypes';
import { EXPERIMENTAL_SORTING_VALUES, SORTING_BY } from '@type/sorting/by';
import { SORTING_DIRECTION, SORTING_DIRECTION_VALUES } from '@type/sorting/direction';
import type { SortingOption } from '@type/sorting/option';

import { getSegmentByValue, getSegmentsByType } from '../getSegment';
import type { SearchUrlParams } from '../types/searchUrlParams';

export interface BuildSearchUrl {
    lang: string;
    transaction?: string;
    estate?: string | null;
    location?: string | null;
    locations?: string;
    market?: Market;
    roomsNumber?: RoomsNumber[];
    useTypes?: UseTypes[];
    isInternalRoute?: boolean;
    shouldUseObsoleteSphere?: boolean;
    queryParameters?: Partial<SearchingFilters>;
    filteringQueryParams?: Partial<SearchingFilters>;
}

const areLocationsValid = (locations: string | unknown[] | null | undefined): locations is Location[] =>
    Array.isArray(locations) &&
    locations.every((location: unknown) => typeof location === 'object' && location !== null && 'id' in location);

const getMarket = (params: SearchUrlParams, shouldForceRemoveMarketFromParams: boolean): Market => {
    const market = (params.market as ExplicitDropdownOption<Market>)?.value || params.market || 'ALL';
    const marketSegments = getSegmentsByType(SEGMENT_TYPE.market);

    const isMarketInSegments = marketSegments?.some(({ value }) => value === market);

    // remove the query parameter if a URL segment is used
    if (isMarketInSegments || market === 'ALL' || shouldForceRemoveMarketFromParams) {
        delete params.market;
    }

    return market;
};

const getRoomsNumber = (params: SearchUrlParams): RoomsNumber[] | undefined => {
    const roomsNumber = params.roomsNumber;

    // remove the query parameter if a URL segment is used
    if (roomsNumber?.length === 1) {
        const roomsNumberSegments = getSegmentsByType(SEGMENT_TYPE.roomsNumber);

        if (roomsNumberSegments?.some((segment) => segment.value === roomsNumber[0])) {
            delete params.roomsNumber;
        }
    }

    return roomsNumber;
};

const getUseTypes = (params: SearchUrlParams, estate: Estate): UseTypes[] | undefined => {
    const useTypes = params.useTypes;

    // remove the query parameter if a URL segment is used
    if (useTypes?.length === 1) {
        const useTypesSegment = getSegmentsByType(SEGMENT_TYPE.useTypes);

        if (estate === ESTATE.commercialProperty && useTypesSegment?.some((segment) => segment.value === useTypes[0])) {
            delete params.useTypes;
        }
    }

    return useTypes;
};

const addTransactionSegment = (url: string, transaction: string): string => {
    const transactionSegment = getSegmentByValue(transaction, SEGMENT_TYPE.transaction)?.label;

    return transactionSegment ? `${url}/${transactionSegment}` : url;
};

const addFiltersSegment = (url: string, filters: string[]): string =>
    filters.length > 0 ? `${url}/${filters.join(',')}` : url;

const addLocationSegment = (url: string, location?: string | null): string => (location ? `${url}/${location}` : url);

const addQueryParameters = (url: string, queryParameters: Partial<SearchingFilters> | undefined): string => {
    // this part of the code is only used to generate the canonical url with the defined query parameter
    // when the url has only parameters from config, it should be added as a query parameter to the url
    // https://naspersclassifieds.atlassian.net/browse/SEORE-713

    if (queryParameters) {
        const keysOfQueryParameters = Object.keys(queryParameters) as Array<keyof SearchingFilters>;
        const definedQueryParameters = keysOfQueryParameters.filter((key) => queryParameters[key] !== undefined);

        let urlWithParams = url;
        if (
            definedQueryParameters.length > 0 &&
            SEARCH_CONFIG.seo?.queryParametersEnabledForIndexing &&
            definedQueryParameters.length <= SEARCH_CONFIG.seo?.queryParametersEnabledForIndexing?.length
        ) {
            const params = definedQueryParameters
                .slice(0, SEARCH_CONFIG.seo?.queryParametersEnabledForIndexing?.length)
                .map((key, index) => `${index === 0 ? '?' : '&'}${key}=${String(queryParameters[key])}`);
            urlWithParams += params.join('');
        }

        return urlWithParams;
    }

    return url;
};

/**
 * Public method for building urls with provided params.
 * @example buildSearchUrl('pl', Transaction.sell, ESTATE.flat, 'mazowieckie/warszawa/warszawa/warszawa')
 * @example /pl/wyniki/sprzedaz/mieszkanie/mazowieckie/warszawa/warszawa/warszawa
 * @returns Proper url link with all segments on right place.
 * @param lang
 * @param transaction
 * @param estate
 * @param location
 * @param locations
 * @param isInternalRoute
 * @param queryParameters
 * @public
 */

export const buildSearchUrl = ({
    lang,
    transaction,
    estate,
    location,
    market,
    roomsNumber,
    useTypes,
    shouldUseObsoleteSphere,
    isInternalRoute = true,
    queryParameters,
}: BuildSearchUrl): string => {
    const routeKey = shouldUseObsoleteSphere ? 'search' : 'domainSearch';
    const { shouldIgnoreMarketSegmentLogs } = SEARCH_BOX_WIDGET_CONFIG;
    const routeSegment = ROUTE_SEGMENT[routeKey];

    // As we are introducing a new variant of the search page based on
    // location domain IDs, we need to differentiate between two possible prefixes
    const prefix = isInternalRoute ? routeKey : routeSegment;

    // Base url for search page. Static 'search' value will be replaced by LocalLink functionality
    // with proper translation for a SITE_CODE
    let url = `/${lang}/${prefix}`;

    // Extend link with transaction if it exists
    if (transaction) {
        url = addTransactionSegment(url, transaction);
    }

    if (estate) {
        const filters: string[] = [];

        filters.push(
            getSegmentByValue(estate, SEGMENT_TYPE.estate)?.label ||
                (getSegmentByValue(ESTATE.flat, SEGMENT_TYPE.estate)?.label as string),
        );

        if (estate !== ESTATE.investment && market && market !== 'ALL' && MARKET_VARIANTS.includes(market as Market)) {
            const segment = getSegmentByValue(
                market,
                SEGMENT_TYPE.market,
                !(shouldIgnoreMarketSegmentLogs && market === 'SECONDARY'),
            )?.label;

            if (segment) {
                filters.push(segment);
            }
        }

        if (roomsNumber?.length === 1) {
            const segment = getSegmentByValue(roomsNumber[0], SEGMENT_TYPE.roomsNumber, false)?.label;

            if (segment) {
                filters.push(segment);
            }
        }

        if (estate === ESTATE.commercialProperty && useTypes?.length === 1) {
            const segment = getSegmentByValue(useTypes[0], SEGMENT_TYPE.useTypes, false)?.label;

            if (segment) {
                filters.push(segment);
            }
        }

        url = addFiltersSegment(url, filters);
    }

    // Extend link with location if it exists
    url = addLocationSegment(url, location);
    url = addQueryParameters(url, queryParameters);

    return url;
};

const getSortingParams = (shouldSetDefaultParams: boolean, sortingOption?: SortingOption): SortingOption | null => {
    const params: SortingOption = { by: SORTING_BY.latest, direction: SORTING_DIRECTION.desc };

    if (!sortingOption) {
        return shouldSetDefaultParams ? params : null;
    }

    if (checkIsArrayValue(EXPERIMENTAL_SORTING_VALUES, sortingOption.by)) {
        params.by = sortingOption.by;
    }

    if (checkIsArrayValue(SORTING_DIRECTION_VALUES, sortingOption.direction)) {
        params.direction = sortingOption.direction;
    }

    return params;
};

/**
 * Public method for building urls with provided search filters.
 * @example createSearchUrlWithParams({ params, lang })
 * @example pl/wyniki/sprzedaz/mieszkanie?roomsNumber=%5Btwo%5D&priceMax=200000&areaMax=300000&market=primary
 * @returns Proper url link with all segments on right place.
 * @param params
 * @example
 * const urlParams = {
 ...filteringQueryParams,
 estate: { value: estate, label: '' },
 transaction: { value: transaction, label: '' },
 market: { value: market, label: '' },
 page: null,
 limit: null,
 isPromoted: null,
 };
 * @param lang
 * @public
 */
export const createSearchUrlWithParams = ({
    params: readonlyParams,
    lang,
    sortingOption,
    viewType,
    mapBounds,
    shouldUseObsoleteSphere = false,
    shouldSetDefaultSortingParams = true,
    shouldForceRemoveMarketFromParams = false,
}: {
    params: SearchUrlParams;
    lang: Locale;
    sortingOption?: SortingOption;
    viewType?: SearchPageVariant;
    mapBounds?: string;
    shouldUseObsoleteSphere?: boolean;
    shouldSetDefaultSortingParams?: boolean;
    shouldForceRemoveMarketFromParams?: boolean;
}): string => {
    /**
     * This function mutates params object, for this reason we need to create a shallow copy
     */
    const params = { ...readonlyParams };
    const selectedEstateOption = params.estate;
    const locations = params.locations;
    const estate = selectedEstateOption?.value ?? ESTATE.flat;
    const selectedTransactionOption = params.transaction;
    const estateSegment = getSegmentByValue(estate, SEGMENT_TYPE.estate);
    const transaction =
        (selectedTransactionOption?.value
            ? getSegmentByValue(selectedTransactionOption.value, SEGMENT_TYPE.transaction)?.value
            : undefined) || TRANSACTION.sell;
    const market: Market = getMarket(params, shouldForceRemoveMarketFromParams);
    const roomsNumber = getRoomsNumber(params);
    const useTypes = getUseTypes(params, estate);

    let location;

    //In New Location Service for single location we remove locations=[] from queryParams
    const shouldRemoveLocationsArray = !shouldUseObsoleteSphere && locations?.length === 1;
    const shouldUseMultipleLocationsLabel = Array.isArray(params.locations) && params.locations.length > 1;

    if (shouldUseMultipleLocationsLabel) {
        location = URL_LOCATION_FILLER.multipleLocationsAreSpecified.label;
    } else if (shouldRemoveLocationsArray && areLocationsValid(locations)) {
        location = locations[0].id;
    } else if (!locations && !params.location) {
        location = URL_LOCATION_FILLER.allLocations.label;
    } else {
        location = params.location?.toString();
    }

    params.locations = shouldRemoveLocationsArray
        ? null
        : createLocationsArrayString(locations as unknown as TreeNode[]);

    const url = buildSearchUrl({
        lang,
        location,
        market,
        roomsNumber,
        useTypes,
        transaction,
        estate: estateSegment?.value,
        isInternalRoute: false,
        shouldUseObsoleteSphere,
    });

    const searchParams = createSearchParams(params);
    const areLocationQueryParamsUnavailable = !location && !params.locations;
    const placeId = params.placeId;

    if (!placeId && (areLocationQueryParamsUnavailable || location === URL_LOCATION_FILLER.allLocations.label)) {
        searchParams.delete(SEARCH_FORM_UNIVERSAL_FIELD.distanceRadius);
    }

    if (searchParams.get(SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated) === 'ANY') {
        searchParams.delete(SEARCH_FORM_UNIVERSAL_FIELD.daysSinceCreated);
    }

    searchParams.delete(SEARCH_FORM_UNIVERSAL_FIELD.location);
    searchParams.delete(SEARCH_FORM_UNIVERSAL_FIELD.estate);
    searchParams.delete(SEARCH_FORM_UNIVERSAL_FIELD.transaction);

    const sortingParams = getSortingParams(shouldSetDefaultSortingParams, sortingOption);
    if (!searchParams.has('by') && sortingParams?.by) {
        searchParams.append('by', sortingParams.by);
    }
    if (!searchParams.has('direction') && sortingParams?.direction) {
        searchParams.append('direction', sortingParams.direction);
    }

    // Attach viewType
    if (viewType) {
        searchParams.append('viewType', viewType);
    }

    if (mapBounds) {
        searchParams.append('mapBounds', mapBounds);
    }

    if (searchParams.has('page') && searchParams.get('page') === '1') {
        searchParams.delete('page');
    }

    const searchParamsAsString = searchParams.toString();
    let queryParams = '';

    if (searchParamsAsString.length > 0) {
        queryParams = `?${searchParamsAsString}`;
    }

    return url + queryParams;
};
