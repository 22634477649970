import { checkIsOption } from '@type/search/dropdownOption';
import type { FormValues } from '@type/search/searchFormValues';

type CreateSearchParams = (params: Omit<FormValues & { [key: string]: unknown }, 'locations'>) => URLSearchParams;

export const createSearchParams: CreateSearchParams = (params) => {
    const checkboxSelectedValue = 'on';
    const searchParams = new URLSearchParams();

    for (const key of Object.keys(params)) {
        const paramValue = params[key];
        let value = null;

        if (checkIsOption(paramValue)) {
            value = paramValue.value;
        } else if (
            /**
             * If field value is of boolean type or
             * when it's a value from a checkbox component
             * convert value to stringified boolean.
             * Checkbox component returns 'on' as an array value when a checkbox is selected.
             */
            paramValue === true ||
            (Array.isArray(paramValue) && paramValue.length === 1 && paramValue[0] === checkboxSelectedValue)
        ) {
            value = 'true';
        } else if (Array.isArray(paramValue) && paramValue.length > 0) {
            value = `[${paramValue.filter(Boolean).toString()}]`;
        } else if (typeof paramValue === 'string' && paramValue.trim().length > 0) {
            value = paramValue;
        } else if (typeof paramValue === 'number') {
            value = `${paramValue}`;
        }

        if (value === null) {
            continue;
        }

        searchParams.append(key, value);
    }

    return searchParams;
};
