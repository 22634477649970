import type { SiteCode } from '@config/siteCode';
import { CURRENT_SITE_CODE } from '@config/siteCode';

const LABEL: Record<SiteCode, Record<'all' | 'multiple', string>> = {
    otodompl: {
        all: 'cala-polska',
        multiple: 'wiele-lokalizacji',
    },
    storiaro: {
        all: 'toata-romania',
        multiple: 'locatii-multiple',
    },
    imovirtualpt2: {
        all: 'todo-o-pais',
        multiple: 'muitas-localizacoes',
    },
};

export const URL_LOCATION_FILLER = {
    allLocations: {
        label: LABEL[CURRENT_SITE_CODE].all,
        value: 'NO_LOCATION_SPECIFIED',
    },
    multipleLocationsAreSpecified: {
        label: LABEL[CURRENT_SITE_CODE].multiple,
        value: 'MULTIPLE_LOCATION_SPECIFIED',
    },
} as const;
